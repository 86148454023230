<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title">App Key</h4>
      <p>Application keys enable you to interact with the our cloud services in your own server, enabling you to send bulk SMS. These credentials are used for authentication, and as such, MUST be kept secret within members of this app.</p>
      <div class="alert alert-info">
        If you suspect that your key may be compromised, you may generate another key, thereby disabling the old one. ( Each app has only 1 key)
      </div>
      <table class="table table-stried" v-if="app.has_key">
        <thead>
          <tr>
            <th>Date Generated</th>
            <th>App Name</th>
            <th>App ID</th>
            <th>Secure</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ app.secret_generated_at }}</td>
            <td>{{ app.name }}</td>
            <td>{{ app.key }}</td>
            <td><span class="badge badge-success"><i class="lni-thumbs-up"></i></span></td>
          </tr>
        </tbody>
      </table>
      <div class="alert alert-warning" v-if="! app.has_key">
        There is no active app key
      </div>
      <a href="#" @click.prevent="submit" class="btn btn-primary btn-sm">{{ app.has_key ? 'Regenerate Key' : 'Generate Key' }}</a>
    </div>
    <modal :show="show_modal" @close="show_modal = false">
      <template slot="header">Application Key</template>
      <p>Your key has been generated.</p>
      <div class="alert alert-success"><h3>{{ secret }}</h3></div>
      <p>Copy the key as it will not be visible later</p>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show_modal: false,
      secret: ''
    }
  },

  methods: {
    submit() {
      this.$axios.post(`/api/v1/apps/${this.$route.params.id}/key`).then(response => {
        let data = this.app
        data.has_key = true
        data.secret_generated_at = response.data.secret_generated_at
        this.$store.dispatch('setApp', data)
        this.show_modal = true;
        this.secret = response.data.secret
      })
    }
  },

  computed: {
    app() {
      return this.$store.getters.getApp
    }
  }
}
</script>